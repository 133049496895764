// Variables
$main-red: #ff3f34;
$sidebar-green: #2ecc71;
$select-black: #222;

// Global element styling
body {
    position: relative;
    // background-color: #191919;
    background-image: url(concretetext.jpg);
    overflow: hidden;
}

html {
    height: 100vh;
    width: 100vw;
    background-image: url(concretetext.jpg);
}

h3 {
    color: white;

}

p {
    color: white;
}

select {
    touch-action: none;
}

.App {
    position: relative;
    width: 80vw;
    min-width: 720px;
    margin-left: 10vw;
    height: 80vh;
    margin-top: 10vh;
    background-color: #080808;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    // box-shadow: 5px 5px 5px rgba(0,0,0,0.7);
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: 20px;
    top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: white;
    ;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: $main-red;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: white;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 80vh !important;
}

/* General sidebar styles */
.bm-menu {
    touch-action: none;
    background: black !important;
    padding: 2.5em 1.5em 0;
    box-shadow: 0px 0px 0px 1px $sidebar-green inset;
    font-size: 1.15em;
    overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: black;
    padding: 0.8em;
    width: 100% !important;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

// Pads and Octave styling
.control-label {
    font-size: 1.5em;
    color: WhiteSmoke;
}

.pad-grid {
    touch-action: none;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 30px;

    display: flex;
    flex-wrap: wrap;


    display: grid;
    grid-template-columns: repeat(4, 100px);
    grid-template-rows: minmax(100px, auto);
    grid-gap: 1em;

    transition: ease all .5ms;
}

.pad {
    cursor: pointer;
    touch-action: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    box-shadow: -3px 3px $main-red, -2px 2px $main-red, -1px 1px $main-red;
    border: solid 1.5px $main-red;
}

.pad:active {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.6);
}

.led {
    height: 10vh;
    width: 20vw;
    border: solid 2px $sidebar-green;
    background: rgba(0, 255, 0, 0.05);
    margin-top: -1px;
}

.canvas {
    height: 10vh;
    width: 20vw;
}

.sidebar-octave-counter {
    float: left;
    width: 50%;
    margin-top: 5px;

    button {
        display: inline-block;
        margin: 0px 3px;
        height: 15px;
        width: 30px;
        color: white;
        background-color: $select-black;
        border: none;
    }

    p {
        display: inline-block;
    }

    h4 {
        margin-bottom: 7px;
    }
}

.sidebar-volume-counter {
    width: 50%;
    float: right;
    margin-top: 5px;

    button {
        display: inline-block;
        margin: 0px 3px;
        height: 15px;
        width: 30px;
        color: white;
        background-color: $select-black;
        border: none;
    }

    p {
        display: inline-block;
    }

    h4 {
        margin-bottom: 7px;
    }
}

.sidebar-synthtype {
    margin-top: 25px;
}

.sidebar-element {
    width: 20vw;

    h4 {
        color: $sidebar-green;
    }

    select {
        color: white;
        background-color: $select-black;
        border-radius: 4px;
        border-color: solid $sidebar-green;
        border: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        option {
            background-color: $select-black;
        }
    }
}

.play-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    width: 20vw;
}

.play {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border: solid .01px $sidebar-green;
}

.stop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border: solid .01px white;
}

.record {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border: solid .01px $main-red;
}

.sidebar-container {
    // border: solid blue;
    display: grid;
    position: absolute;
    width: 30vw;
    height: 78%;
    margin: 0;
    place-items: center center;

    select {
        width: 100%;
    }

    select:active,
    select:hover {
        outline-color: $sidebar-green;
    }
}

.knob {
    display: flex;
    position: relative;
    float: right;
    margin: 0 40px;
    margin-top: 60px;
    transition: ease all 1ms;
}

.knob .ticks {
    position: absolute;
}

.knob .ticks .tick {
    position: absolute;
    background: black;
    box-shadow: inset 0 0 0 0 black;
    width: 3px;
    transition: box-shadow 0.5s;
}

.knob .ticks .tick.active {
    box-shadow: inset 0 0 5px 2px $main-red, 0 0 0 1px $main-red;
}

.knob.outer {
    border-radius: 50%;
    border: 1px solid $select-black;
    border-bottom: 5px solid $select-black;

    box-shadow: 0 5px 15px 2px black, 0 0 5px 3px black, 0 0 0 12px #444;
}

.knob.inner {
    border-radius: 50%;
}

.knob.inner .grip {
    position: absolute;
    width: 5%;
    height: 5%;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 3px 1px white;
}

.head-container {
    position: relative;
    width: 100%;
    height: 150px;
}

.knob-panel {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    height: 150px;
    width: 40%;
    margin-right: 20px;
}

.title {
    position: relative;
    display: grid;
    width: 30%;
    height: 150px;
    font-size: 3em;
    margin-left: 70px;

    h3 {
        margin: 0;
        align-self: end;

    }
}

@media (max-width: 1250px) {
    .pad-grid {
        grid-template-columns: repeat(4, 110px);
        grid-template-rows: minmax(110px, auto);
        grid-gap: 1em;
        margin-bottom: 20px;
        margin-right: 45px;
    }

    .pad {
        height: 110px;
        width: 110px;
    }

    .knob {
        margin-top: 35px;
    }

    .sidebar-container {
        width: 25vw;
        height: 78%;
        margin: 0;
        margin-left: 45px;
    }
}

@media (max-width: 1150px) {
    .pad-grid {
        grid-template-columns: repeat(4, 110px);
        grid-template-rows: minmax(110px, auto);
        grid-gap: 1em;
        margin-right: 40px;
    }

    .pad {
        height: 110px;
        width: 110px;
    }

    .knob {
        margin-top: 45px;
    }

    .knob-panel {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        height: 100px;
        margin-right: 40px;
    }

    .sidebar-container {
        width: 25vw;
        height: 78%;
        margin-left: 45px;
    }
}

@media (max-width: 1080px) {
    .pad-grid {
        grid-template-columns: repeat(4, 110px);
        grid-template-rows: minmax(110px, auto);
        grid-gap: 1em;
        margin-right: 30px;
    }

    .knob {
        margin-top: 45px;
    }

    .knob-panel {
        display: flex;
        justify-content: flex-end;
        height: 150px;
        margin-right: 10px;
    }

    .title {
        margin-left: 45px;
    }

    .sidebar-container {
        width: 23vw;
        height: 78%;
        margin: 0;
        margin-left: 30px;
    }
}

@media (max-width: 1020px) {
    .pad-grid {
        grid-template-columns: repeat(4, 110px);
        grid-template-rows: minmax(110px, auto);
        grid-gap: 1em;
        margin-right: 30px;
    }

    .knob {
        margin-top: 50px;
    }

    .knob-panel {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        height: 150px;
        margin-right: 10px;
    }

    .title {
        margin-left: 25px;
    }

    .sidebar-container {
        width: 19vw;
        height: 78%;
        margin: 0;
        margin-left: 30px;
    }

    .sidebar-element {
        width: 19vw;
    }

    .led {
        width: 19vw;
    }
}

@media (max-width: 1000px) {
    .sidebar-container {
        width: 17vw;
        height: 78%;
        margin: 0;
        margin-left: 30px;
    }

    .sidebar-element {
        width: 17vw;
    }

    .led {
        width: 17vw;
    }

    .knob {
        margin-top: 50px;
    }

    .knob-panel {
        height: 150px;
    }
}

@media (max-width: 965px) {
    .pad-grid {
        grid-template-columns: repeat(4, 100px);
        grid-template-rows: minmax(100px, auto);
        grid-gap: 1em;
        margin-right: 30px;
    }

    .pad {
        height: 100px;
        width: 100px;
    }

    .knob {
        margin-top: 60px;
    }

    .knob-panel {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        height: 150px;
        margin-right: 10px;
    }

    .sidebar-container {
        width: 15vw;
        height: 78%;
        margin: 0;
        margin-left: 30px;
    }

    .sidebar-element {
        width: 15vw;
    }

    .led {
        width: 15vw;
    }

    .sidebar-octave-counter {
        button {
            display: inline-block;
            margin: 3px 5px;
            width: 20px;
            color: white;
            background-color: $select-black;
        }

        p {
            display: inline-block;
        }

        h4 {
            margin-bottom: 7px;
        }
    }

    .sidebar-volume-counter {
        button {
            display: inline-block;
            margin: 3px 5px;
            width: 20px;
            color: white;
            background-color: $select-black;
        }

        p {
            display: inline-block;
        }

        h4 {
            margin-bottom: 7px;
        }
    }
}

@media (max-width: 940px) {
    .sidebar-volume-counter {
        button {
            display: inline-block;
            margin: 3px 5px;
            width: 18px;
            color: white;
            background-color: $select-black;
        }

        p {
            display: inline-block;
        }

        h4 {
            margin-bottom: 7px;
        }
    }

    .sidebar-octave-counter {
        button {
            display: inline-block;
            margin: 3px 5px;
            width: 18px;
            color: white;
            background-color: $select-black;
        }

        p {
            display: inline-block;
        }

        h4 {
            margin-bottom: 7px;
        }
    }
}

@media (max-width: 900px) {
    .App {
        width: 100vw;
        margin-left: 0;
    }

    .pad-grid {
        position: absolute;
        grid-template-columns: repeat(4, 100px);
        grid-template-rows: minmax(100px, auto);
        grid-gap: 1em;
        margin-right: 30px;
        margin-left: 73px;
    }

    .pad {
        height: 100px;
        width: 100px;
    }

    .knob {
        margin-top: 60px;
        margin-right: 30px;
    }

    .knob-panel {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        height: 150px;
        margin-right: 30px;
    }

    .sidebar-container {
        width: 13vw;
        height: 78%;
        margin: 0;
        margin-left: 30px;
    }

    .sidebar-element {
        width: 20vw;
    }

    .bm-menu-wrap {
        position: absolute;
        top: 0;
        height: 100vh !important;
    }
}

@media(max-width: 800px) {
    .App {
        width: 100vw;
        margin-left: 0;
    }

    .pad-grid {
        position: absolute;
        grid-template-columns: repeat(4, 100px);
        grid-template-rows: minmax(100px, auto);
        grid-gap: 1em;
    }

    .pad {
        height: 100px;
        width: 100px;
    }

    .sidebar-element {
        width: 25vw;
    }

    .led {
        width: 25vw;
    }
}

@media(max-width: 500px) {
    .sidebar-element {
        width: 25vw;
    }
}

@media(max-width: 420px) {
    .App {
        min-width: 0;
        height: 100vh;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pad-grid {
        position: absolute;
        // bottom: 0;
        // left: 0;
        // margin: 0;
        // margin-left: 31px;
        // margin-bottom: 20px;


        display: flex;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(3, 110px);
        grid-template-rows: minmax(110px, auto);
        grid-gap: 1em;

        transform: translate(0, -150px);
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        width: 110px;
    }

    .sidebar-element {
        width: 45vw;
    }

    .led {
        width: 45vw;
    }

    .bm-menu {
        height: 100%;
    }

    .bm-menu-wrap {
        position: fixed;
        height: 100vh !important;
    }

    .head-container {
        position: relative;
        width: 100%;
        height: 200px;
    }

    .knob-panel {
        position: absolute;
        // bottom: 0;
        // left: -20px;
        // top: 100px;
        display: flex;
        justify-content: center;
        padding: 1em;
        height: 75px;
        width: 100vw;
        transform: translate(40px, -250px);
    }

    .knob {
        display: flex;
        position: relative;
        float: right;
        margin: 0 40px;
        margin-top: 0px;
        transition: ease all 1ms;
    }

    .title {
        position: relative;
        display: grid;
        width: 100vw;
        ;
        height: 75px;
        font-size: 3em;
        // margin-left: 70px;
        transform: translate(40px, -350px);

        h3 {
            margin: 0;
            margin-left: 70px;
        }
    }
}

@media(max-height: 720px) and (max-width: 420px) {
    .title {
        transform: translate(40px, -260px);
    }

    .pad-grid {
        transform: translate(0, -20px);
    }

    .knob-panel {
        transform: translate(35px, -180px);
    }
}

@media(max-width: 380px) {
    .app {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pad-grid {
        position: absolute;
        // bottom: 0;
        // left: 0;
        // margin: 0;
        // margin-left: 32px;
        // margin-bottom: 20px;


        display: flex;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(3, 100px);
        grid-template-rows: minmax(100px, auto);
        grid-gap: 1em;

        transform: translate(10px, 0px);
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
    }

    .sidebar-element {
        width: 45vw;
    }

    .bm-menu {
        height: 100%;
    }

    .head-container {
        position: relative;
        width: 100%;
        height: 200px;
    }

    .knob-panel {
        // position: absolute;
        // bottom: 0;
        // left: -20px;
        // top: 90px;
        display: flex;
        justify-content: center;
        padding: 1em;
        height: 75px;
        width: 100vw;
        transform: translate(45px, -115px);
    }

    .knob {
        display: flex;
        position: relative;
        float: right;
        margin: 0 40px;
        margin-top: 0px;
        transition: ease all 1ms;
    }

    .title {
        position: relative;
        display: grid;
        width: 100vw;
        ;
        height: 75px;
        font-size: 3em;
        // margin-left: 70px;
        transform: translate(40px, -180px);

        h3 {
            margin: 0;
            margin-left: 40px;
        }
    }
}

@media(max-height: 680px) and (max-width: 380px) {
    .title {
        transform: translate(40px, -240px);
    }

    .knob-panel {
        transform: translate(40px, -150px);
    }
}

@media(max-height: 650px) and (max-width: 380px) {
    .title {
        transform: translate(40px, -220px);
    }
}

@media(max-height: 630px),
(max-width: 380px) {
    .title {
        transform: translate(40px, -220px);
    }

    .pad-grid {
        grid-template-columns: repeat(3, 90px);
        grid-template-rows: minmax(90px, auto);
        grid-gap: 1.5em;

        transform: translate(-15px, 0px);
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        width: 90px;
    }
}

@media(max-height: 600px) and (max-width: 380px) {
    .title {
        transform: translate(40px, -190px);
    }

    .knob-panel {
        transform: translate(40px, -123px);
    }

    .pad-grid {
        grid-template-columns: repeat(3, 83px);
        grid-template-rows: minmax(83px, auto);
        grid-gap: 1em;
        column-gap: 2em;

        transform: translate(0px, 0px);
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 83px;
        width: 83px;
    }
}


@media(max-width: 365px) {
    .pad-grid {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // margin: 0;
        // margin-left: 19px;
        // margin-bottom: 60px;


        display: flex;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(3, 85px);
        grid-template-rows: minmax(85px, auto);
        grid-gap: 1em;
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 85px;
        width: 85px;
    }

    .sidebar-element {
        width: 45vw;
    }

    .bm-menu {
        height: 100%;
    }

    .head-container {
        position: relative;
        width: 100%;
        height: 200px;
    }

    .knob-panel {
        // position: absolute;
        // bottom: 0;
        // left: -20px;
        // top: 100px;
        display: flex;
        justify-content: center;
        padding: 1em;
        height: 75px;
        width: 100vw;
    }

    .knob {
        display: flex;
        position: relative;
        float: right;
        margin: 0 40px;
        margin-top: 0px;
        transition: ease all 1ms;
    }

    .title {
        position: relative;
        display: grid;
        width: 100vw;
        ;
        height: 75px;
        font-size: 3em;
        transform: translate(50px, -190px);

        h3 {
            margin: 0;
            margin-left: 25px;
        }
    }
}

@media(max-width: 330px) {
    .App {
        min-width: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pad-grid {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // margin: 0;
        // margin-left: 21px;
        // margin-bottom: 30px;


        display: flex;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(3, 80px);
        grid-template-rows: minmax(80px, auto);
        grid-gap: 1em;
        // grid-column-gap: 2em;
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
    }

    .sidebar-element {
        width: 35vw;
    }

    .bm-menu {
        height: 100%;
    }

    .bm-burger-button {
        position: absolute;
        width: 31px;
        height: 25px;
        left: 20px;
        top: 26px;
    }

    .head-container {
        position: relative;
        width: 100vw;
        height: 80px;
    }

    .knob-panel {
        display: flex;
        justify-content: center;
        padding: 1em;
        height: 75px;
        width: 100vw;
        transform: translate(45px, -170px);
    }

    .knob {
        display: flex;
        position: relative;
        float: right;
        margin: 0 30px;
        margin-top: 0px;
        transition: ease all 1ms;
    }

    .title {
        position: relative;
        display: grid;
        width: 100vw;
        ;
        height: 60px;
        font-size: 2em;
        transform: translate(60px, -245px);

        h3 {
            margin: 0;
            margin-left: 55px;
        }
    }
}

@media(max-height: 480px) and (max-width: 330px) {
    .title {
        transform: translate(50px, -190px);
    }

    .pad-grid {
        grid-template-columns: repeat(3, 60px);
        grid-template-rows: minmax(60px, auto);
        grid-gap: 1em;
        column-gap: 2em;

        transform: translate(-8px, 0px);
    }

    .pad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
    }

    .knob-panel {
        transform: translate(40px, -140px);
    }
}